import { cn } from '@mr-yum/frontend-ui'
import React, { PropsWithChildren } from 'react'

interface Props {
  className?: string
}

export const MenuBadge = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(
        'z-[1] flex items-center justify-center rounded-md bg-interactive-surface px-2 py-1 my-label-xs',
        className,
      )}
    >
      {children}
    </div>
  )
}
