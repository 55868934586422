import {
  RouteLink,
  RouteParam,
} from '@mr-yum/frontend-core/dist/services/routes'
import { BlockedFilledIcon } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import { MenuBadge } from 'components/Menu/MenuBadge'
import { MenuSectionWaitTime } from 'components/Menu/MenuPage'
import { useRewardItemDetails } from 'components/Rewards/hooks/useRewardItemDetails'
import { menuItemSerializer } from 'components/Tracking/utils'
import { useLogger } from 'hooks/useLogger'
import { GuestPriceData, GuestWaitTime } from 'lib/gql'
import { MenuItemPartsFragment } from 'lib/gql.types'
import { routes } from 'lib/routes'
import React, { ComponentProps, HTMLAttributes, PropsWithChildren } from 'react'
import { getScrollPercentage, getScrollPixels } from 'utils/env'

import { RenderableMenuItem } from './utils'

type ItemLinkProps = {
  menuItem: RenderableMenuItem
  pathData: RouteParam<typeof routes.menuItem>
  menuSectionWaitTime?: MenuSectionWaitTime
  menuCategoryWaitTime?: GuestWaitTime
}

export const AccentTag = ({
  inactive = false,
  ...props
}: PropsWithChildren<{ inactive?: boolean }>) => (
  <div
    className={cn(
      'inline-flex items-center whitespace-nowrap rounded-sm px-1.5 pb-1 pt-0.5 text-center my-label-xs',
      {
        'bg-semantic-emphasis-surface-subtle text-semantic-emphasis-foreground-subtle':
          !inactive,
      },
      {
        'bg-surface-inverted text-foreground-inverted': inactive,
      },
    )}
    data-testid="menu-item-tag"
    {...props}
  />
)

export const ItemWrapper = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className="w-full bg-surface-ui-background text-foreground" {...props}>
    {children}
  </div>
)

export const ItemLinkWrapper = ({
  menuItem,
  pathData,
  menuSectionWaitTime,
  menuCategoryWaitTime,
  children,
}: PropsWithChildren<ItemLinkProps>) => {
  const { logEvent } = useLogger()
  const rewardDetails = useRewardItemDetails(menuItem?.rewardPrice)

  const isLocked = !rewardDetails.isRewardAffordable

  if (isLocked) {
    return <div className="cursor-not-allowed">{children}</div>
  }

  const anchorProps = {
    onClick: () => {
      logEvent('Click menu item', {
        menuItem: menuItemSerializer(menuItem),
        scrollPercentage: getScrollPercentage(),
        scrollPixels: getScrollPixels(),
        flexibleWaitTimes: {
          menuSectionWaitTime,
          menuCategoryWaitTime,
        },
      })
    },
    'data-testid': 'menu-item-link',
  }

  return (
    <RouteLink
      route={routes.menuItemModal}
      pathData={pathData}
      shallow
      scroll={false}
    >
      <a {...anchorProps}>{children}</a>
    </RouteLink>
  )
}

export const Item = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="group relative h-0 w-full overflow-hidden rounded border bg-surface pb-[40%] text-foreground"
    {...props}
  >
    {children}
  </div>
)

export const ItemImage = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'relative aspect-square w-2/5 flex-none bg-surface-subtle',
      className,
    )}
    {...props}
  >
    {children}
  </div>
)

export const ItemName = ({
  children,
  ...props
}: HTMLAttributes<HTMLHeadingElement>) => (
  <h3
    className="mb-1 capitalize text-foreground my-label-md"
    data-testid="menu-item-name"
    {...props}
  >
    {children}
  </h3>
)

export const ItemDescription = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="text-foreground transition-colors duration-300 ease-out my-body-sm"
    data-testid="menu-item-description"
    {...props}
  >
    {children}
  </div>
)

export const ItemPriceWrapper = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex', className)} {...props}>
    {children}
  </div>
)

export const ItemPrice = ({
  className,
  priceData,
}: Pick<MenuItemPartsFragment, 'priceData'> &
  ComponentProps<typeof ItemPriceWrapper>) => {
  if (!priceData.displayPrice) {
    return <></>
  }

  const displayPriceNumeric = parseInt(
    priceData.displayPrice.replace(/[^0-9]/g, ''),
  )

  if (isNaN(displayPriceNumeric) || displayPriceNumeric === 0) {
    return <></>
  }

  const itemOnSpecial = getIsOnSpecialTagVisible(priceData)

  const isCustomDisplayPrice = !isNaN(Number(priceData.displayPrice))

  return (
    <ItemPriceWrapper data-testid="menu-item-price" className={className}>
      {((itemOnSpecial && isCustomDisplayPrice) ||
        priceData.isMembershipPricing) && (
        <span
          className="mr-1 text-foreground-subtle line-through"
          data-testid="menu-item-original-display-price"
        >
          {priceData.originalDisplayPrice}
        </span>
      )}
      <div data-testid="menu-item-display-price">{priceData.displayPrice}</div>
    </ItemPriceWrapper>
  )
}

export const ItemDietaryFilters = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      className,
      'truncate rounded bg-surface-bright px-2 py-1 text-right',
    )}
    data-testid="menu-item-dietary-filters"
    {...props}
  >
    {children}
  </div>
)

export const ImageUnavailableOverlay = () => (
  <div className="absolute left-0 top-0 z-[1] flex h-full w-full items-center justify-center bg-black bg-opacity-60">
    <MenuBadge className="absolute left-2 top-2">
      <BlockedFilledIcon className="mr-1 h-4 w-4 " /> Sold out
    </MenuBadge>
  </div>
)

export const ItemGrid = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="grid grid-cols-1 gap-3 md:grid-cols-[repeat(auto-fill,minmax(456px,1fr))] md:gap-5 lg:gap-10"
    {...props}
  >
    {children}
  </div>
)

export const ListItemWrapper = ({
  children,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div
    className="rounded border bg-interactive-subtle-surface hover:bg-interactive-subtle-surface-hover"
    {...props}
  >
    {children}
  </div>
)

export const hasPriceOverrideDiscount = (priceData: GuestPriceData) => {
  const { hasPriceOverride, originalPriceInCents, priceInCents } = priceData
  if (hasPriceOverride && originalPriceInCents && priceInCents) {
    return priceInCents < originalPriceInCents
  }
  return false
}

export const getIsPopularTagVisible = (params: {
  menuItem: { isPopular: boolean }
  priceData: GuestPriceData
}) => {
  return params.menuItem.isPopular && !params.priceData.isMembershipPricing
}

export const getIsOnSpecialTagVisible = (priceData: GuestPriceData) => {
  const priceInCents = priceData.priceInCents ?? 0
  const originalPriceInCents = priceData.originalPriceInCents ?? 0

  return (
    priceData.hasPriceOverride &&
    priceInCents < originalPriceInCents &&
    !priceData.isMembershipPricing
  )
}
